<template>
  <section>
    <v-container fluid>
      <v-row>
        <v-col md="12">
          <PageHeader :title="headercontent.title" />
        </v-col>
        <v-col md="7">
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            class="form-section"
          >
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="title"
                  outlined
                  label="Title"
                  :rules="titleRules"
                ></v-text-field>
              </v-col>
              <v-col cols="6" v-if="!loading">
                <v-select
                  v-model="categoryHandle"
                  :items="allVideoCategory.results.list"
                  label="Category"
                  item-text="title"
                  item-value="categoryHandle"
                  outlined
                  :rules="categoryHandleRules"
                ></v-select>
              </v-col>
              <v-col cols="6" v-if="!loading">
                <v-select
                  v-model="subCategoryHandle"
                  :items="allVideoSubCategory.results.list"
                  label="Sub Category"
                  item-text="title"
                  item-value="subCategoryHandle"
                  outlined
                  :rules="subCategoryRules"
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="videoType"
                  :items="videoTypes"
                  label="Video Type"
                  outlined
                  :rules="videoTypeRules"
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="duration"
                  outlined
                  label="Duration"
                  type="text"
                  :rules="durationRules"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="status"
                  :items="statuses"
                  label="Status"
                  outlined
                  item-text="name"
                  item-value="status"
                  :rules="statusRules"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <quill-editor
                  ref="myQuillEditor"
                  v-model="description"
                  :options="editorOption"
                  :rules="descriptionRules"
                />
              </v-col>
              <v-col cols="6" class="file-input">
                <v-file-input
                  v-model="video"
                  outlined
                  label="Choose Video"
                  accept="video/*"
                  @change="previewVideo"
                  :rules="!$route.params.id ? videoRules : []"
                ></v-file-input>
                <v-col cols="12" v-if="videoPreviewUrl">
                  <video
                    :src="videoPreviewUrl"
                    controls
                    width="100%"
                    height="auto"
                  ></video>
                </v-col>
              </v-col>
              <v-col cols="6" class="file-input">
                <v-file-input
                  v-model="image"
                  outlined
                  label="Choose Video Cover"
                  accept="image/*"
                  @change="previewCover"
                  :rules="!$route.params.id ? imageRules : []"
                ></v-file-input>
                <v-col cols="12" v-if="coverPreviewUrl">
                  <img
                    :src="coverPreviewUrl"
                    alt=""
                    width="100%"
                    height="auto"
                  />
                </v-col>
              </v-col>
              <v-col cols="12" class="submitbtn">
                <v-btn
                  :disabled="!valid"
                  class="submitbutton btn-dark"
                  @click="validate"
                  :loading="submitLoading"
                >
                  {{ headercontent.title }}
                </v-btn>
              </v-col>
              <v-snackbar v-model="snackbar" :color="snackbarColor">
                {{ snackbarMessage }}
              </v-snackbar>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import PageHeader from "@/components/dashboard/PageHeader";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "addVideo",
  components: { PageHeader },
  data: () => ({
    valid: false,
    title: "",
    description: "",
    categoryHandle: null,
    subCategoryHandle: null,
    videoType: null,
    duration: null,
    status: true,
    video: null,
    image: null,
    videoPreviewUrl: null,
    coverPreviewUrl: null,
    headercontent: {
      title: "Add Video",
      addlink: "/dashboard/video/add",
      downloadlink: true,
    },
    editorOption: {
      // Quill options...
    },
    titleRules: [(v) => !!v || "Video Title is required"],
    categoryHandleRules: [(v) => !!v || "Category is required"],
    subCategoryRules: [(v) => !!v || "Sub Category is required"],
    videoTypeRules: [(v) => !!v || "Video Type is required"],
    durationRules: [(v) => !!v || "Duration is required"],
    statusRules: [(v) => !!v || "Status is required"],
    descriptionRules: [(v) => !!v || "Description is required"],
    videoRules: [(v) => !!v || "Video is required"],
    imageRules: [(v) => !!v || "Cover image is required"],
    statuses: [
      { name: "Active", status: true },
      { name: "Inactive", status: false },
    ],
    videoTypes: ["paid", "not paid"],
    loading: true,
    submitLoading: false,
    snackbar: false,
  }),

  methods: {
    ...mapActions(["addVideo", "fetchCategoryVideo", "fetchVideoSubCategory","fetchSingleVideo"]),
    async validate() {
      if (this.$refs.form.validate()) {
        this.submitLoading = true;
        let fd = new FormData();
        if(this.$route.params.id){
          fd.append("_id", this.$route.params.id);
          this.snackbarMessage = "Video Updated Successfully";
        }else{
          this.snackbarMessage = "Video Added Successfully";
        }
        fd.append("title", this.title);
        fd.append("description", this.description);
        fd.append("category", this.categoryHandle);
        fd.append("subCategory", this.subCategoryHandle);
        fd.append("videoType", this.videoType);
        fd.append("duration", this.duration);
        fd.append("status", this.status);
        fd.append("image", this.image);
        fd.append("video", this.video);
        fd.append("fileType", "images");
        fd.append("vfileType", "videos");
        fd.append("imageFile", "videos");

        await this.addVideo(fd);
        this.snackbar = true;
        this.submitLoading = false;
      }
    },
    previewVideo(file) {
      if (file) {
        this.videoPreviewUrl = URL.createObjectURL(file);
      } else {
        this.videoPreviewUrl = null;
      }
    },
    previewCover(file) {
      if (file) {
        this.coverPreviewUrl = URL.createObjectURL(file);
      } else {
        this.coverPreviewUrl = null;
      }
    },
  },
  computed: {
    ...mapGetters(["allVideoCategory", "allVideoSubCategory","allSingleVideo"]),
    editor() {
      return this.$refs.myQuillEditor.quill;
    },
  },
  async created() {
    await this.fetchCategoryVideo({ categoryType: "video" });
    await this.fetchVideoSubCategory({ categoryType: "video" });
    this.loading = false;
    if (this.$route.params.id) {
      await this.fetchSingleVideo(this.$route.params.id);
      this.title = this.allSingleVideo.results.title;
      this.categoryHandle = this.allSingleVideo.results.category;
      this.subCategoryHandle = this.allSingleVideo.results.subCategory;
      this.videoType = this.allSingleVideo.results.videoType;
      this.duration = this.allSingleVideo.results.duration;
      this.status = this.allSingleVideo.results.status;
      this.description = this.allSingleVideo.results.description;
      this.videoPreviewUrl = 'https://app.necojobs.com.np'+this.allSingleVideo.results.videoUrl;
      this.coverPreviewUrl = 'https://app.necojobs.com.np'+ this.allSingleVideo.results.imageUrl;
      this.headercontent.title = "Edit Video";
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.btn-dark {
  background: $tagcolor !important;
  color: $white;
  width: 200px;
  min-height: 50px;
}
.file-input {
  margin-top: 26px;
}
</style>
